<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
// filters
const filter_deliveries = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_delivery_details = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const deliveries = ref([])
const delivery_notes = ref([])

const get_deliveries = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/get-deliveries")
        .then(response => {
            deliveries.value = response.data
            console.log("deliveries", deliveries.value)
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}
const get_delivery_notes = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/get-delivery-notes")
        .then(response => {
            delivery_notes.value = response.data
            console.log("delivery_notes", delivery_notes.value)
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}
onMounted(() => {
    get_deliveries()
    get_delivery_notes()
})
const delete_delivery = (id) => {
    confirm.require({
        message: 'Bist du sicher, dass du diese Auslieferung löschen möchtest?',
        header: 'Rechnung: ' + id + ' löschen?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Auslieferung löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            toast.add({ severity: 'warn', summary: 'Auslieferung gelöscht!', detail: 'Auslieferung ' + id + ' wurde gelöscht', life: 3000})
        }
    })
}
const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}
const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const deliveryDetailsDialog = ref(false)
const delivery_details = ref([])
const open_delivery_details = (details) => {
    for (const order of details.orders) {
        for (const product of order.lines) {
            delivery_details.value.push({
                sku: product.sku,
                order: order.orderId,
                quantity: product.quantity,
                name: product.name,
                kgEquiv: product.kgEquiv,
                processingCost: product.processingCost
            })
        }
    }
    deliveryDetailsDialog.value = true
    console.log("delivery_details",delivery_details.value)
}

const dt_delivery = ref()
const exportCSV = () => {
    dt_delivery.value.exportCSV()
}
</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <div class="col-12 mb-2">
        <Toolbar>
            <template #start>
                <span class="p-input-icon-left mr-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filter_deliveries['global'].value" placeholder="Suche" style="width: 300px" />
                </span>
            </template>
        </Toolbar>
    </div>
    <DataTable v-model:filters="filter_deliveries" :value="deliveries" :rows="20" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" :autoLayout="true">
        <Column field="date" header="Datum">
            <template #body="slotProps">
                {{ format_swiss_date(slotProps.data.date) }}
            </template>
        </Column>
        <Column header="Umfang">
            <template #body="slotProps">
                <Button @click="open_delivery_details(slotProps.data)" :label="String(slotProps.data.orders.length) + ' Aufträge'" icon="pi pi-eye" size="small" class="w-auto" />
            </template>
        </Column>
        <Column field="userEmail" header="Kunde">
            <template #body="slotProps">
                <span>{{ slotProps.data.userEmail }}</span>
            </template>
        </Column>
        <Column field="delivery_note" header="Lieferschein">
            <template #body>
                <Dropdown :options="delivery_notes" optionLabel="description" optionValue="id" placeholder="Lieferschein auswählen" />
            </template>
        </Column>
        <Column>
            <template #body="slotProps">
                <Button @click="delete_delivery(slotProps.data.id)" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" />
            </template>
        </Column>
    </DataTable>

    <Dialog v-model:visible="deliveryDetailsDialog" :style="{width: '1200px'}" header="Auslieferung Details" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <DataTable ref="dt_delivery" :value="delivery_details" v-model:filters="filter_delivery_details" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100,200]"  :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column field="sku" header="SKU" />
                    <Column field="quantity" header="Anzahl" />
                    <Column field="name" header="Produkt" />
                    <Column field="kgEquiv" header="Kg-Eq" />
                    <Column field="processingCost" header="Verarbeitungskosten" />
                    <Column field="order" header="Auftrag" />
                </DataTable>
            </div>
        </div>
    </Dialog>
</template>